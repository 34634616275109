import * as React from 'react';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import HomePreview from '../templates/home';
import Layout from 'components/layout';

const NotFoundPage = () => (
  <Layout>
    <h1>Sorry, Page not found!</h1>
  </Layout>
);

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    homepage: HomePreview,
  },
});
